import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#11A64A",
    },
    badgeColor: {
      main: " #F3692E",
    },
  },
});
