import Box from "@mui/material/Box";
import colorLogo from "../../images/colorLogo.svg";

export default function LoadingIcon() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          animation: "pulse 1.5s infinite ease-in-out",
          "@keyframes pulse": {
            "0%": { transform: "scale(1)", opacity: 1 },
            "50%": { transform: "scale(1.1)", opacity: 0.7 },
            "100%": { transform: "scale(1)", opacity: 1 },
          },
        }}
      >
        <img src={colorLogo} width="100%" height="100%" />
      </Box>
    </Box>
  );
}
