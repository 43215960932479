import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useHistory, useLocation } from "react-router-dom";

import whiteLogo from "../../images/whiteLogo.svg";
import {
  CONTACT_ROUTE,
  EXPLORE_ROUTE,
  HOME_ROUTE,
  PRIVACY_ROUTE,
  TERMS_CONDITIONS_ROUTE,
} from "../../utils/routes";

const navItems = [
  { text: "Home", link: HOME_ROUTE },
  { text: "Explore", link: EXPLORE_ROUTE },
  { text: "Contact Us", link: CONTACT_ROUTE },
];

const bottomItems = [
  { text: "Privacy Policy", link: PRIVACY_ROUTE },
  { text: "Terms of Service", link: TERMS_CONDITIONS_ROUTE },
  // { text: 'Cookies Settings', link: '#' }
];

export default function Footer() {
  const { pathname } = useLocation();
  const { push } = useHistory();

  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        display: pathname.split("/").includes("admin") ? "none" : "block",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "70px",
          }}
        >
          <img src={whiteLogo} width="100px" height="100px" />
        </Box>
        <Box
          sx={{
            paddingTop: "30px",
          }}
        >
          <Stack
            direction={{ md: "row" }}
            spacing={2}
            sx={{
              display: { md: "flex" },
              justifyContent: { md: "center" },
            }}
          >
            {navItems.map((item, index) => (
              <Box
                onClick={() => push(item.link, { sortBy: "all" })}
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "white",
                  fontSize: { md: "16px" },
                  cursor: "pointer",
                }}
                key={index}
              >
                {item.text}
              </Box>
            ))}
          </Stack>
        </Box>
        <Box
          sx={{
            paddingBottom: "30px",
            paddingTop: "50px",
          }}
        >
          <Divider
            sx={{
              borderColor: "white",
            }}
          />
        </Box>
        <Box
          sx={{
            paddingTop: "30px",
            paddingBottom: { md: "30px" },
          }}
        >
          <Box
            sx={{
              display: { md: "flex" },
              flexDirection: { md: "row-reverse" },
              justifyContent: { md: "space-between" },
            }}
          >
            <Stack direction={{ md: "row" }} spacing={2}>
              {bottomItems.map((item, index) => (
                <Box
                  onClick={() => push(item.link)}
                  sx={{
                    textAlign: "center",
                    fontweight: 600,
                    color: "white",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  key={index}
                >
                  {item.text}
                </Box>
              ))}
            </Stack>
            <Box
              sx={{
                paddingTop: { xs: "40px", md: "0px" },
                paddingBottom: { xs: "20px", md: "0px" },
                display: "flex",
                justifyContent: "center",
                color: "white",
              }}
            >
              2022 Kunoch Foods. All right reserved.
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
